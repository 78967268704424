import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import useLanguage from '../../hooks/useLanguage';

import SeoHeader from '../../components/SeoHeader/SeoHeader';
import SiteLayout from '../../components/Layouts/SiteLayout';
import Loading from '../../components/Loading/Loading';

import Request from '../../tools/Request';

import i18n from '../../i18n/i18n';

const useQuery = () => new URLSearchParams(useLocation().search);

const ActivateEmailScreen = () => {
  const query = useQuery();
  const { language } = useLanguage();

  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const key = query.get('key');
    const code = query.get('code');

    if (!key || key === '') {
      setResult({
        message: i18n.t('invalidParameters'),
      });

      setLoading(false);
    } else if (!code || code === '') {
      setResult({
        message: i18n.t('invalidParameters'),
      });

      setLoading(false);
    } else {
      const runAsync = async () => {
        setLoading(true);

        const parameters = {
          method: 'POST',
          url: 'v1/activateemail',
          apiType: 'memberApi',
          data: {
            memberId: key,
            activationCode: code,
          },
          language: language.appLanguage,
        };

        const request = await Request.getResponse(parameters);

        if (request.status === 200) {
          setResult({ message: request.data.title });
        } else {
          setResult({ message: request.title });
        }

        setLoading(false);
      };

      runAsync();
    }
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <SeoHeader robots={false} title={i18n.t('activateEmail')} />
      <SiteLayout headerType='white' footerType='green'>
        <section className='gray member-padding member-back'>
          <div className='container'>
            <div className='box min-box'>
              <div className='box-tabs'>
                <ul>
                  <li>
                    <div className='active'>{i18n.t('activateEmail')}</div>
                  </li>
                </ul>
              </div>
              <div className='transition'>
                {result && <p>{result.message}</p>}
                <div>
                  <Link
                    href={`/${language.appLanguage}`}
                    className='button button-green button-large'
                  >
                    {i18n.t('goToHomePage')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </SiteLayout>
    </>
  );
};

export default ActivateEmailScreen;
