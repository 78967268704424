import i18n from 'i18n-js';
import tr from './locales/tr';
import en from './locales/en';

i18n.fallbacks = true;
i18n.locales.no = 'tr';

i18n.translations = {
  tr,
  en,
};

export default i18n;
