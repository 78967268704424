import { Link } from 'react-router-dom';

import SiteLayout from '../../components/Layouts/SiteLayout';
import SeoHeader from '../../components/SeoHeader/SeoHeader';
import useLanguage from '../../hooks/useLanguage';
import i18n from '../../i18n/i18n';

const OrderSuccess = () => {
  const { language } = useLanguage();
  i18n.locale = language.appLanguage;

  return (
    <>
      <SeoHeader
        robots={false}
        title={i18n.t('orderSuccessTitle')}
        description={i18n.t('orderSuccessDescription')}
        canonical='https://community.breathhub.app/order/success'
      />
      <SiteLayout headerType='white' footerType='green'>
        <section className='white padding'>
          <div className='container'>
            <div className='row'>
              <div className='col-6'>
                <div className='section-title center'>
                  <h4>{i18n.t('orderSuccessTitle')}</h4>

                  <p>{i18n.t('orderSuccessDescription')}</p>
                </div>

                <p className='center'>
                  <Link to='/members/subscriptions' className='button button-green button-medium'>
                    {i18n.t('goToSubscriptions')}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </section>
      </SiteLayout>
    </>
  );
};

export default OrderSuccess;
