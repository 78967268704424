import { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import useMember from '../../hooks/useMember';

import Loading from '../../components/Loading/Loading';

import Request from '../../tools/Request';

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

const ManageSubscription = () => {
  const query = useQuery();
  const { member } = useMember();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const runAsync = async () => {
      if (query) {
        setLoading(true);
        const parameters = {
          method: 'GET',
          url: `v1/stripe/portal?origin=${query.get('origin')}`,
          apiType: 'purchaseApi',
          headers: [{ AccessToken: member.token }],
        };

        const request = await Request.getResponse(parameters);

        if (request.status === 200) {
          document.location = request.data.results;
        }

        setLoading(false);
      }
    };

    runAsync();
  }, [query]);

  if (loading) {
    return <Loading />;
  }

  return <div>Navigating...</div>;
};

export default ManageSubscription;
