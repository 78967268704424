import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useMember from '../../hooks/useMember';
import useLanguage from '../../hooks/useLanguage';

import SeoHeader from '../../components/SeoHeader/SeoHeader';
import SiteLayout from '../../components/Layouts/SiteLayout';
import SuccessMessage from '../../components/Forms/SuccessMessage';
import Sidebar from '../../components/Common/Sidebar';
import FormInput from '../../components/Forms/FormInput';
import FormButton from '../../components/Forms/FormButton';
import Loading from '../../components/Loading/Loading';

import Request from '../../tools/Request';

import i18n from '../../i18n/i18n';

const ChangeEmailScreen = () => {
  const navigate = useNavigate();

  const { language } = useLanguage();
  const { member, isMemberSignedin } = useMember();

  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formValues, setFormValues] = useState({
    emailAddress: '',
    emailAddressAgain: '',
  });

  useEffect(() => {
    const runAsync = async () => {
      const serverMember = await isMemberSignedin(language.appLanguage);

      if (serverMember) {
        setLoading(false);
      } else {
        navigate('/members/signin?redirect=/members/change-email');
      }
    };

    runAsync();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const parameters = {
      method: 'POST',
      url: 'v1/changeemail',
      apiType: 'memberApi',
      data: {
        emailAddress: formValues.emailAddress,
        emailAddressAgain: formValues.emailAddressAgain,
      },
      headers: [{ AccessToken: member.token }],
      language: language.appLanguage,
    };

    const request = await Request.getResponse(parameters);

    if (request.status === 200) {
      navigate('/members');
    } else {
      setLoading(false);

      setSuccess({ title: i18n.t('error'), message: request.title, buttonText: i18n.t('ok') });
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <SeoHeader robots={false} title={i18n.t('changeEmail')} />
      <SiteLayout headerType='white absolute' footerType='green' hideFooter>
        <section className='gray flex sidebar-container'>
          <Sidebar />
          <div className='right-content flex flex-column flex-center'>
            <div className='box min-box'>
              <div className='box-tabs'>
                <ul>
                  <li>
                    <div className='active'>{i18n.t('changeEmail')}</div>
                  </li>
                </ul>
              </div>
              <div className='transition'>
                <p>{i18n.t('changeEmailDescription')}</p>
                <form className='form' onSubmit={handleSubmit} noValidate>
                  <div className='form-elements'>
                    <div className='form-line'>
                      <label htmlFor='email'>{i18n.t('newEmailLabel')}</label>
                      <FormInput
                        type='email'
                        name='emailAddress'
                        value={formValues.emailAddress}
                        placeholder={i18n.t('newEmailPlaceholder')}
                        onChange={handleChange}
                      />
                    </div>
                    <div className='form-line'>
                      <label htmlFor='emailAddressAgain'>{i18n.t('newEmailConfirmLabel')}</label>
                      <FormInput
                        type='email'
                        name='emailAddressAgain'
                        value={formValues.emailAddressAgain}
                        placeholder={i18n.t('newEmailConfirmPlaceholder')}
                        onChange={handleChange}
                      />
                    </div>
                    <div className='form-line'>
                      <div className='buttons'>
                        <FormButton
                          type='submit'
                          text={i18n.t('changeEmail')}
                          onClick={handleSubmit}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {success && (
          <SuccessMessage
            title={success.title}
            message={success.message}
            buttonText={success.buttonText}
            click={() => setSuccess(null)}
          />
        )}
      </SiteLayout>
    </>
  );
};

export default ChangeEmailScreen;
