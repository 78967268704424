import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import parse from 'html-react-parser';

import useLanguage from '../../hooks/useLanguage';

import SeoHeader from '../../components/SeoHeader/SeoHeader';
import SiteLayout from '../../components/Layouts/SiteLayout';
import HelpSearch from '../../components/Help/HelpSearch';
import Loading from '../../components/Loading/Loading';

import Request from '../../tools/Request';

import i18n from '../../i18n/i18n';

const CreateHtmlContentArea = ({ html }) => {
  if (html) {
    return <div className='html-content'>{parse(html)}</div>;
  }

  return null;
};

const AnswerScreen = () => {
  const { url } = useParams();
  const { language } = useLanguage();

  const helpUrl = language.appLanguage === 'en' ? 'help' : 'yardim';
  const categoryUrl = language.appLanguage === 'en' ? 'category' : 'kategori';
  const answerUrl = language.appLanguage === 'en' ? 'answer' : 'cevap';

  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(true);
  const [founded, setFounded] = useState(true);
  const [categories, setCategories] = useState(null);
  const [categoryAnswers, setCategoryAnswers] = useState(null);

  const getCategories = async () => {
    const parameters = {
      method: 'GET',
      url: 'v1/help/categories',
      apiType: 'contentApi',
      language: language.appLanguage,
    };

    const request = await Request.getResponse(parameters);

    if (request.status === 200) {
      return request.data.results;
    }

    return null;
  };

  const getCategoryAnswers = async (categoryUri) => {
    const parameters = {
      method: 'GET',
      url: `v1/help/category/${categoryUri}`,
      apiType: 'contentApi',
      language: language.appLanguage,
    };

    const request = await Request.getResponse(parameters);

    if (request.status === 200) {
      return request.data.results;
    }

    return null;
  };

  useEffect(() => {
    const runAsync = async () => {
      setLoading(true);

      const parameters = {
        method: 'GET',
        url: `v1/help/answer/${url}`,
        apiType: 'contentApi',
        language: language.appLanguage,
      };

      const request = await Request.getResponse(parameters);

      if (request.status === 200) {
        const answer = request.data.results;

        setResults(answer);
        const otherCategories = await getCategories();

        if (otherCategories) {
          setCategories(otherCategories);
        }

        const otherAnswers = await getCategoryAnswers(answer.categoryUrl);

        if (otherAnswers) {
          setCategoryAnswers(otherAnswers);
        }
      } else {
        setFounded(false);
      }

      setLoading(false);
    };

    runAsync();
  }, [url]);

  if (loading) {
    return <Loading />;
  }

  if (!founded) {
    return null;
  }

  return (
    <>
      <SeoHeader
        robots
        title={results.title}
        description={results.title}
        keywords={`help, help answer, ${results.title}`}
        canonical={`https://community.breathhub.app/help/answer/${url}`}
      />
      <SiteLayout headerType='white' footerType='green'>
        <section className='green-header'>
          <div className='container'>
            <div className='row'>
              <div className='col-6'>
                <HelpSearch />
              </div>
            </div>
          </div>
        </section>
        <section className='help-categories-top'>
          {categories &&
            categories.map((item) => (
              <div key={item.id.toString()}>
                <Link
                  to={`/${language.appLanguage}/${helpUrl}/${categoryUrl}/${item.url}`}
                  className={
                    results.categoryUrl === item.url
                      ? 'button button-orange button-mini'
                      : 'button button-light-green button-mini'
                  }
                >
                  {item.name}
                </Link>
              </div>
            ))}
        </section>
        <section className='white content-padding'>
          <div className='container'>
            <div className='row'>
              <div className='col-1 responsive-hide'>
                <div className='help-details-answers'>
                  <h5>{results.category}</h5>
                  {categoryAnswers &&
                    categoryAnswers.map((item) => (
                      <div key={item.id.toString()}>
                        <Link
                          to={`/${language.appLanguage}/${helpUrl}/${answerUrl}/${item.url}`}
                          className={results.url === item.url ? 'active' : 'passive'}
                        >
                          {item.title}
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
              <div className='col-5'>
                <div className='help-details'>
                  <h1>{results.title}</h1>

                  <div className='html-content'>
                    <CreateHtmlContentArea html={results.content} />
                  </div>

                  <p className='right'>
                    <br />
                    <br />
                    <Link
                      to={`/${language.appLanguage}/help`}
                      className='button button-green button-large'
                    >
                      {i18n.t('helpMainButton')}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </SiteLayout>
    </>
  );
};

export default AnswerScreen;
