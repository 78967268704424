import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useLanguage from '../../hooks/useLanguage';

import SeoHeader from '../../components/SeoHeader/SeoHeader';
import SiteLayout from '../../components/Layouts/SiteLayout';
import HelpSearch from '../../components/Help/HelpSearch';
import HelpClose from '../../components/Help/HelpClose';
import Loading from '../../components/Loading/Loading';

import Request from '../../tools/Request';

const CategoryScreen = () => {
  const { url } = useParams();
  const navigate = useNavigate();
  const { language } = useLanguage();

  const helpUrl = language.appLanguage === 'en' ? 'help' : 'yardim';
  const categoryUrl = language.appLanguage === 'en' ? 'category' : 'kategori';
  const answerUrl = language.appLanguage === 'en' ? 'answer' : 'cevap';

  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(true);
  const [founded, setFounded] = useState(true);

  useEffect(() => {
    const runAsync = async () => {
      const parameters = {
        method: 'GET',
        url: `v1/help/category/${url}`,
        apiType: 'contentApi',
        language: language.appLanguage,
      };

      const request = await Request.getResponse(parameters);

      if (request.status === 200) {
        setResults(request.data.results);
      } else {
        setFounded(false);
      }

      setLoading(false);
    };

    runAsync();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (!founded) {
    return null;
  }

  return (
    <>
      <SeoHeader
        robots
        title={results && results[0].seoTitle}
        description={results && results[0].seoDescription}
        keywords={`help, help category ${results && results[0].category}`}
        canonical={`https://community.breathhub.app/${language.appLanguage}/${helpUrl}/${categoryUrl}/${url}`}
      />
      <SiteLayout headerType='white' footerType='green'>
        <section className='green-header'>
          <div className='container'>
            <div className='row'>
              <div className='col-6'>
                <HelpSearch />
              </div>
            </div>
          </div>
        </section>
        <section className='white content-padding'>
          <div className='container'>
            <div className='row'>
              <div className='col-6'>
                <h1 className='help-category center'>{results && results[0].seoH1}</h1>

                <div className='help-mini'>
                  {results &&
                    results.map((item) => (
                      <HelpClose
                        noIcon
                        key={item.id.toString()}
                        id={item.id}
                        question={item.title}
                        answer={item.title}
                        opened={false}
                        onClick={() =>
                          navigate(`/${language.appLanguage}/${helpUrl}/${answerUrl}/${item.url}`)
                        }
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </SiteLayout>
    </>
  );
};

export default CategoryScreen;
