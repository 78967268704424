import { memo } from 'react';
import useLanguage from '../../hooks/useLanguage';
import PremiumCard from '../Cards/PremiumCard';

// TODO member bu community satın almış ise kontrolünü yap sonra
const Premium = memo(({ data, isHaveSub }) => {
  const { language } = useLanguage();

  /*
  const getName = (type) => {
    let str = '';
    if (type === 'monthly') {
      if (language.appLanguage === 'en') {
        str = 'Monthly Payment';
      } else if (language.appLanguage === 'tr') {
        str = 'Aylık Ödeme';
      }
    } else if (type === 'yearly') {
      if (language.appLanguage === 'en') {
        str = 'Yearly Payment';
      } else if (language.appLanguage === 'tr') {
        str = 'Yıllık Ödeme';
      }
    } else if (type === 'oneTime') {
      if (language.appLanguage === 'en') {
        str = 'One Time Payment';
      } else if (language.appLanguage === 'tr') {
        str = 'Peşin Ödeme';
      }
    }

    return str;
  };
*/
  /*
  const getPriceWithCurrency = (price, currency) => {
    let lang;
    if (currency === 'USD') {
      lang = 'en-US';
    } else if (currency === 'TRY') {
      lang = 'tr-TR';
    } else if (currency === 'GBP') {
      lang = 'en-GB';
    }

    return new Intl.NumberFormat(lang, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(parseFloat(price));
  };

  */
  /*
  const getPricing = (type) => {
    let str = '';

    const discountRateMonthly = parseFloat(data?.discountRateForMonthly);
    const discountRateYearly = parseFloat(data?.discountRateForYearly);
    const discountRateOneTime = parseFloat(data?.discountRateForOneTime);

    if (data) {
      if (type === 'monthly') {
        if (language.appLanguage === 'en') {
          if (discountRateMonthly) {
            str = `Only <strong>${getPriceWithCurrency(
              (parseFloat(data?.monthlyPrice) * (100.0 - discountRateMonthly)) / 100.0,
              data?.currency
            )}</strong> instead of <del>${getPriceWithCurrency(
              data?.monthlyPrice,
              data?.currency
            )}</del> per month.`;
          } else {
            str = `<strong>${getPriceWithCurrency(
              data?.monthlyPrice,
              data?.currency
            )}</strong> per month.`;
          }
        } else if (language.appLanguage === 'tr') {
          if (discountRateMonthly) {
            str = `Aylık <del>${getPriceWithCurrency(
              data?.monthlyPrice,
              data?.currency
            )}</del> yerine sadece <strong>${getPriceWithCurrency(
              (parseFloat(data?.monthlyPrice) * (100.0 - discountRateMonthly)) / 100.0,
              data?.currency
            )}</strong>.`;
          } else {
            str = `Aylık <strong>${getPriceWithCurrency(
              data?.monthlyPrice,
              data?.currency
            )}</strong>.`;
          }
        }
      } else if (type === 'yearly') {
        if (language.appLanguage === 'en') {
          if (discountRateYearly) {
            str = `Only <strong>${getPriceWithCurrency(
              ((parseFloat(data?.yearlyPrice) / 12.0) * (100.0 - discountRateYearly)) / 100.0,
              data?.currency
            )}</strong> instead of <del>${getPriceWithCurrency(
              parseFloat(data?.yearlyPrice) / 12.0,
              data?.currency
            )}</del> per month. Billed at <strong>${getPriceWithCurrency(
              (parseFloat(data?.yearlyPrice) * (100.0 - discountRateYearly)) / 100.0,
              data?.currency
            )}</strong> instead of <del>${getPriceWithCurrency(
              data?.yearlyPrice,
              data?.currency
            )}</del>`;
          } else {
            str = `<strong>${getPriceWithCurrency(
              parseFloat(data?.yearlyPrice) / 12.0,
              data?.currency
            )}</strong> per month. Billed at <strong>${getPriceWithCurrency(
              data?.yearlyPrice,
              data?.currency
            )}</strong>`;
          }
        } else if (language.appLanguage === 'tr') {
          if (discountRateYearly) {
            str = `Aylık <del>${getPriceWithCurrency(
              parseFloat(data?.yearlyPrice) / 12.0,
              data?.currency
            )}</del> yerine sadece <strong>${getPriceWithCurrency(
              ((parseFloat(data?.yearlyPrice) / 12.0) * (100.0 - discountRateYearly)) / 100.0,
              data?.currency
            )}</strong>. Toplam <del>${getPriceWithCurrency(
              data?.yearlyPrice,
              data?.currency
            )}</del> yerine sadece <strong>${getPriceWithCurrency(
              (parseFloat(data?.yearlyPrice) * (100.0 - discountRateYearly)) / 100.0,
              data?.currency
            )}</strong>`;
          } else {
            str = `Aylık <strong>${getPriceWithCurrency(
              parseFloat(data?.yearlyPrice) / 12.0,
              data?.currency
            )}</strong>. Toplam <strong>${getPriceWithCurrency(
              data?.yearlyPrice,
              data?.currency
            )}</strong>`;
          }
        }
      } else if (type === 'oneTime') {
        if (language.appLanguage === 'en') {
          if (discountRateOneTime) {
            str = `Only <strong>${getPriceWithCurrency(
              (parseFloat(data?.oneTimePrice) * (100.0 - discountRateOneTime)) / 100.0,
              data?.currency
            )}</strong> instead of <del>${getPriceWithCurrency(
              data?.oneTimePrice,
              data?.currency
            )}</del> one time.`;
          } else {
            str = `<strong>${getPriceWithCurrency(
              data?.oneTimePrice,
              data?.currency
            )}</strong> one time.`;
          }
        } else if (language.appLanguage === 'tr') {
          if (discountRateMonthly) {
            str = `Peşin <del>${getPriceWithCurrency(
              data?.oneTimePrice,
              data?.currency
            )}</del> yerine sadece <strong>${getPriceWithCurrency(
              (parseFloat(data?.oneTimePrice) * (100.0 - discountRateOneTime)) / 100.0,
              data?.currency
            )}</strong>.`;
          } else {
            str = `Peşin <strong>${getPriceWithCurrency(
              data?.oneTimePrice,
              data?.currency
            )}</strong>.`;
          }
        }
      }
    }

    return str;
  };
  */

  /*
  const getDescription = (type) => {
    let str = '';
    const discountRateMonthly = parseFloat(data?.discountRateForMonthly);
    const discountRateYearly = parseFloat(data?.discountRateForYearly);
    const discountRateOneTime = parseFloat(data?.discountRateForOneTime);

    if (type === 'monthly' && discountRateMonthly) {
      if (language.appLanguage === 'en') {
        str = `%${discountRateMonthly.toFixed(0)} discount`;
      } else if (language.appLanguage === 'tr') {
        str = `%${discountRateMonthly.toFixed(0)} indirim`;
      }
    } else if (type === 'yearly' && discountRateYearly) {
      if (language.appLanguage === 'en') {
        str = `%${discountRateYearly.toFixed(0)} discount`;
      } else if (language.appLanguage === 'tr') {
        str = `%${discountRateYearly.toFixed(0)} indirim`;
      }
    } else if (type === 'oneTime' && discountRateOneTime) {
      if (language.appLanguage === 'en') {
        str = `%${discountRateOneTime.toFixed(0)} discount`;
      } else if (language.appLanguage === 'tr') {
        str = `%${discountRateOneTime.toFixed(0)} indirim`;
      }
    }

    return str;
  };
  */

  return (
    <section id='premium' className='white' style={{ position: 'relative' }}>
      <div
        className='hero premium-hero'
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('${data.picture}')`,
        }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-6'>
              <div className='section-title'>
                <h3>
                  <strong>{data?.name}</strong>
                </h3>
                <p>{data?.shortDescription}</p>
              </div>
            </div>
          </div>

          {!isHaveSub && (
            <div className='pricing row flex flex-row flex-center'>
              {parseFloat(data?.monthlyPrice) > 0 && (
                <div className='col-2'>
                  <PremiumCard
                    path={data?.path}
                    period='monthly'
                    name={
                      language.appLanguage === 'en'
                        ? data?.monthlyPaymentTitle?.replaceAll('<p><br></p>', '')
                        : data?.monthlyPaymentTitleTr?.replaceAll('<p><br></p>', '')
                    }
                    pricing={
                      language.appLanguage === 'en'
                        ? data?.monthlyPaymentDes?.replaceAll('<p><br></p>', '')
                        : data?.monthlyPaymentDesTr?.replaceAll('<p><br></p>', '')
                    }
                    location='TR'
                    transparent
                  />
                </div>
              )}

              {parseFloat(data?.yearlyPrice) > 0 && (
                <div className='col-2'>
                  <PremiumCard
                    path={data?.path}
                    period='yearly'
                    name={
                      language.appLanguage === 'en'
                        ? data?.yearlyPaymentTitle?.replaceAll('<p><br></p>', '')
                        : data?.yearlyPaymentTitleTr?.replaceAll('<p><br></p>', '')
                    }
                    pricing={
                      language.appLanguage === 'en'
                        ? data?.yearlyPaymentDes?.replaceAll('<p><br></p>', '')
                        : data?.yearlyPaymentDesTr?.replaceAll('<p><br></p>', '')
                    }
                    location='TR'
                  />
                </div>
              )}

              {parseFloat(data?.oneTimePrice) > 0 && (
                <div className='col-2'>
                  <PremiumCard
                    path={data?.path}
                    period='oneTime'
                    name={
                      language.appLanguage === 'en'
                        ? data?.oneTimePaymentTitle?.replaceAll('<p><br></p>', '')
                        : data?.oneTimePaymentTitleTr?.replaceAll('<p><br></p>', '')
                    }
                    pricing={
                      language.appLanguage === 'en'
                        ? data?.oneTimePaymentDes?.replaceAll('<p><br></p>', '')
                        : data?.oneTimePaymentDesTr?.replaceAll('<p><br></p>', '')
                    }
                    location='TR'
                    transparent
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
});

export default Premium;
