import { memo } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import useLanguage from '../../hooks/useLanguage';
import i18n from '../../i18n/i18n';

const CommunityCard = memo(({ name, description, backgroundImage, path, id }) => {
  const { language } = useLanguage();

  const detailPathUrl = language?.appLanguage === 'en' ? 'community' : 'topluluk';

  const toggleMeetingButton = () => {
    let meetingLink;
    if (id === 14) {
      meetingLink = document.querySelector('#SOIBTN_10xFitnessGzellikveyilikProgram');
    } else if (id === 12) {
      meetingLink = document.querySelector('#SOIBTN_mufredatdonusturucukocluk');
    } else if (id === 11) {
      meetingLink = document.querySelector('#SOIBTN_NefesKocluguSertifikaProgramEkibi');
    } else if (id === 16) {
      meetingLink = document.querySelector('#SOIBTN_markaolzenginol');
    } else if (id === 15) {
      meetingLink = document.querySelector('#SOIBTN_mucizekursu');
    } else {
      meetingLink = document.querySelector('#SOIBTN_15DakikalknGrmeSeans');
    }
    if (meetingLink) {
      meetingLink.click();
    }

    localStorage.setItem('meeting-path', path);
  };

  return (
    <div
      className='hero partner center no-select community community-card-container'
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('${backgroundImage}')`,
      }}
    >
      <h4>{name}</h4>
      <div className='html-content' style={{ paddingLeft: 5 }}>
        {parse(description)}
      </div>
      <button
        type='button'
        className='button button-orange button-medium community-card-meeting-btn center'
        onClick={toggleMeetingButton}
      >
        {i18n.t('createMeeting')}
      </button>
      <Link
        to={`/${language.appLanguage}/${detailPathUrl}/${path}`}
        className='button button-green button-medium community-card-sub-btn center'
      >
        {i18n.t('selectCard')}
      </Link>
    </div>
  );
});

export default CommunityCard;
