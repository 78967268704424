import { memo, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FiHome, FiUser, FiAtSign, FiLock, FiBox, FiLogOut } from 'react-icons/fi';

import i18n from '../../i18n/i18n';

const Sidebar = memo(() => {
  const location = useLocation();

  const [loc, setLoc] = useState('');

  useEffect(() => {
    const path = location.pathname.toLowerCase();

    if (path) {
      setLoc(path);
    }
  }, []);

  return (
    <div className='sidebar flex flex-center'>
      <div>
        <ul>
          <li>
            <Link to='/members' className={loc.match(/^(\/members)[/]?$/g) ? 'active' : 'passive'}>
              <FiHome />
              <p>{i18n.t('accountHome')}</p>
            </Link>
          </li>
          <li>
            <Link
              to='/members/edit-profile'
              className={
                loc.includes('/members/edit-profile') || loc.includes('/members/edit-profile/')
                  ? 'active'
                  : 'passive'
              }
            >
              <FiUser />
              <p>{i18n.t('editProfile')}</p>
            </Link>
          </li>
          <li>
            <Link
              to='/members/change-email'
              className={
                loc.includes('/members/change-email') || loc.includes('/members/change-email/')
                  ? 'active'
                  : 'passive'
              }
            >
              <FiAtSign />
              <p>{i18n.t('changeEmail')}</p>
            </Link>
          </li>
          <li>
            <Link
              to='/members/change-password'
              className={
                loc.includes('/members/change-password') ||
                loc.includes('/members/change-password/')
                  ? 'active'
                  : 'passive'
              }
            >
              <FiLock />
              <p>{i18n.t('changePassword')}</p>
            </Link>
          </li>
          {/*
           <li>
            <Link
              to='/members/notifications'
              className={
                loc.includes('/members/notifications') || loc.includes('/members/notifications/')
                  ? 'active'
                  : 'passive'
              }
            >
              <FiBell />
              <p>{i18n.t('notifications')}</p>
            </Link>
          </li>
           */}
          <li>
            <Link
              to='/members/subscriptions'
              className={
                loc.includes('/members/subscriptions') || loc.includes('/members/subscriptions/')
                  ? 'active'
                  : 'passive'
              }
            >
              <FiBox />
              <p>{i18n.t('subscriptions')}</p>
            </Link>
          </li>
        </ul>
      </div>
      <div className='sidebar-bottom'>
        <Link
          to='/members/signout'
          className={
            loc.includes('/members/signout') || loc.includes('/members/signout/')
              ? 'active'
              : 'passive'
          }
        >
          <FiLogOut />
          <p>{i18n.t('signout')}</p>
        </Link>
      </div>
    </div>
  );
});

export default Sidebar;
