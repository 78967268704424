import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import useMember from '../../hooks/useMember';
import useLanguage from '../../hooks/useLanguage';

import SeoHeader from '../../components/SeoHeader/SeoHeader';
import SiteLayout from '../../components/Layouts/SiteLayout';
import SuccessMessage from '../../components/Forms/SuccessMessage';
import Loading from '../../components/Loading/Loading';

import Request from '../../tools/Request';

import i18n from '../../i18n/i18n';

const SignoutScreen = () => {
  const navigate = useNavigate();

  const { language } = useLanguage();
  const { removeMember, isMemberSignedin } = useMember();

  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(true);

  const signOut = async (token) => {
    const parameters = {
      method: 'POST',
      url: 'v1/signout',
      apiType: 'memberApi',
      headers: [{ AccessToken: token }],
      language: language.appLanguage,
    };

    const request = await Request.getResponse(parameters);

    if (request.status === 200) {
      removeMember();
    } else {
      setSuccess({ title: i18n.t('error'), message: request.title, buttonText: i18n.t('ok') });
    }

    setLoading(false);
  };

  useEffect(() => {
    const runAsync = async () => {
      const serverMember = await isMemberSignedin(language.appLanguage);

      if (serverMember) {
        await signOut(serverMember.token);
      } else {
        removeMember();

        navigate('/members/signin');
      }
    };

    runAsync();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <SeoHeader robots={false} title={i18n.t('signout')} />
      <SiteLayout headerType='white' footerType='green'>
        <section className='member-padding member-back'>
          <div className='container'>
            <div className='box min-box'>
              <div className='box-tabs'>
                <ul>
                  <li>
                    <div className='active'>{i18n.t('signout')}</div>
                  </li>
                </ul>
              </div>
              <div className='transition'>
                <p>{i18n.t('signedOut')}</p>
                <Link
                  href={`/${language.appLanguage}`}
                  className='button button-green button-medium'
                >
                  {i18n.t('goToHome')}
                </Link>
              </div>
            </div>
          </div>
        </section>
        {success && (
          <SuccessMessage
            title={success.title}
            message={success.message}
            buttonText={success.buttonText}
            click={() => setSuccess(null)}
          />
        )}
      </SiteLayout>
    </>
  );
};

export default SignoutScreen;
