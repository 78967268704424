import { memo } from 'react';
import PropTypes from 'prop-types';

const ErrorMessage = memo(({ message }) => (
  <div className='error-message no-select'>
    <p>{message}</p>
  </div>
));

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ErrorMessage;
