import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useLanguage from '../../hooks/useLanguage';

import CommunityList from '../../components/Sections/CommunityList';
// import People from '../../components/Sections/People';
import SeoHeader from '../../components/SeoHeader/SeoHeader';
import SiteLayout from '../../components/Layouts/SiteLayout';
import Loading from '../../components/Loading/Loading';
import Request from '../../tools/Request';

const useQuery = () => new URLSearchParams(useLocation().search);

const HomeScreen = () => {
  const query = useQuery();
  const navigate = useNavigate();

  const { language } = useLanguage();

  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState(null);

  const oneItemCheck = (data) => {
    if (Array.isArray(data) && data.length === 1) {
      const detailPathUrl = language?.appLanguage === 'en' ? 'community' : 'topluluk';
      navigate(`/${language.appLanguage}/${detailPathUrl}/${data[0]?.path}`);
    }
  };
  useEffect(() => {
    setLoading(false);
  }, [language]);

  useEffect(() => {
    const dl = query.get('dl');
    if (dl) {
      window.location.replace(dl);
    }
  }, []);

  useEffect(() => {
    const runAsync = async () => {
      setLoading(true);

      const parameters = {
        method: 'GET',
        url: `v1/community/list`,
        apiType: 'memberApi',
        language: language.appLanguage,
      };

      const request = await Request.getResponse(parameters);

      if (request.status === 200) {
        oneItemCheck(request.data.results);
        setResults(request.data.results);
      }

      setLoading(false);
    };

    runAsync();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <SeoHeader
        robots
        title='Breath Hub Community'
        description='Breath Hub. Take a deep breath, your life is about to change.'
        keywords='breath hub, breathhub, breath hub app, your breath journey, take a deep breath, download breath hub, breath hub ios, breath hub android'
        canonical='https://community.breathhub.app'
      />
      <SiteLayout headerType='white' footerType='green'>
        <CommunityList community={results} loading={loading} />
        {/*
        <People people={results} loading={loading} />
        */}
      </SiteLayout>
    </>
  );
};

export default HomeScreen;
