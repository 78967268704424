import { useState, useEffect } from 'react';

import useLanguage from '../../hooks/useLanguage';
import SiteMapConfig from '../../config/SiteMapConfig';

import Loading from '../../components/Loading/Loading';

const SitemapScreen = () => {
  const { language } = useLanguage();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, [language]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {SiteMapConfig.map((item) => (
        <div className='col-12' key={item.Url.toString()}>
          <a href={`${item.Url}`} target='_blank' rel='noreferrer'>
            {item.Url}
          </a>
        </div>
      ))}
    </>
  );
};

export default SitemapScreen;
