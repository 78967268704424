import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

import Request from '../tools/Request';
import Database from '../tools/Database';

const tableName = 'member';

const initialState = {
  member: null,
  removeMember: () => {},
  registerMember: () => {},
  isMemberSignedin: async () => {},
};

export const MemberContext = createContext(initialState);

const MemberProvider = ({ children }) => {
  const [member, setMember] = useState(Database.getData(tableName) || initialState.member);

  /**
   * Add or update signed in member in database.
   *
   * @param {object} details - Member data
   */
  const registerMember = (details) => {
    Database.setData(tableName, details);

    setMember(details);
  };

  /**
   * Remove signed in member in database.
   */
  const removeMember = () => {
    Database.removeData(tableName);

    setMember(null);
  };

  /**
   * Get signed in member state. If db contains no member data returns null.
   */
  const isMemberSignedin = async (language) => {
    const savedMember = Database.getData(tableName);

    if (!savedMember) {
      return null;
    }

    const parameters = {
      method: 'GET',
      url: 'v1/member',
      apiType: 'memberApi',
      headers: [{ AccessToken: savedMember.token }],
      language,
    };

    const request = await Request.getResponse(parameters);

    // Successful request
    if (request.status === 200) {
      const memberDetails = request.data.results;

      registerMember(memberDetails);

      return memberDetails;
    }

    // Member not authorized
    if (request.status === 401) {
      removeMember();

      return null;
    }

    // Error occurred
    return savedMember;
  };

  /**
   * Create context values.
   */
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue = {
    member,
    removeMember: () => removeMember(),
    registerMember: (details) => registerMember(details),
    isMemberSignedin: async (language) => isMemberSignedin(language),
  };

  return <MemberContext.Provider value={contextValue}>{children}</MemberContext.Provider>;
};

MemberProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MemberProvider;
