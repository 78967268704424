import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const initialState = false;

export const DrawerContext = createContext(initialState);

const DrawerProvider = ({ children }) => {
  const [showDrawer, setShowDrawer] = useState(initialState);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <DrawerContext.Provider value={[showDrawer, setShowDrawer]}>{children}</DrawerContext.Provider>
  );
};

DrawerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DrawerProvider;
