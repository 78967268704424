import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

import Database from '../tools/Database';

const tableName = 'location';

const initialState = {
  location: {
    appLocation: '',
  },
  registerLocation: () => {},
};

export const LocationContext = createContext(initialState);

const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState(Database.getData(tableName) || initialState.location);

  /**
   * Add or update location parameters in database.
   *
   * @param {object} details - Location data
   */
  const registerLocation = (details) => {
    setLocation(details);

    Database.setData(tableName, details);
  };

  /**
   * Create context values.
   */
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue = {
    location,
    registerLocation: (details) => registerLocation(details),
  };

  return <LocationContext.Provider value={contextValue}>{children}</LocationContext.Provider>;
};

LocationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LocationProvider;
