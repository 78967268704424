import { memo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import useLanguage from '../../hooks/useLanguage';
import useLocation from '../../hooks/useLocation';

import Header from '../Headers/Header';
import Footer from '../Footers/Footer';
import Loading from '../Loading/Loading';
import ScrollToTop from '../Common/ScrollToTop';

const SiteLayout = memo(({ headerType, footerType, hideFooter, children }) => {
  const { lang } = useParams();

  const { language, registerLanguage } = useLanguage();
  const { location, registerLocation } = useLocation();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (lang) {
      if (lang === 'en' || lang === 'tr') {
        if (lang !== language.appLanguage) {
          const parameters = {
            appLanguage: lang,
          };

          registerLanguage(parameters);
          const loc = document.location.href.replace(`/${language.appLanguage}/`, `/${lang}/`);

          document.location = loc;
        }
      }
    }
    if (location.appLocation === '') {
      fetch('https://api.ipregistry.co/?key=tryout')
        .then((response) => response.json())
        .then((payload) => {
          const parameters = { appLocation: payload.location.country.code };
          registerLocation(parameters);
        });
    }

    setLoading(false);
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <ScrollToTop />
      <div className='site'>
        <Header type={headerType} />
        {children}
        {!hideFooter && <Footer type={footerType} />}
      </div>
    </>
  );
});

SiteLayout.defaultProps = {
  hideFooter: false,
};

SiteLayout.propTypes = {
  children: PropTypes.node.isRequired,
  hideFooter: PropTypes.bool,
  headerType: PropTypes.string.isRequired,
  footerType: PropTypes.string.isRequired,
};

export default SiteLayout;
