import { useState, useEffect } from 'react';

import useLanguage from '../../hooks/useLanguage';

import SeoHeader from '../../components/SeoHeader/SeoHeader';
import SiteLayout from '../../components/Layouts/SiteLayout';
import HelpSearch from '../../components/Help/HelpSearch';
import HelpBox from '../../components/Help/HelpBox';
import Loading from '../../components/Loading/Loading';

import i18n from '../../i18n/i18n';

import Request from '../../tools/Request';

const HelpScreen = () => {
  const { language } = useLanguage();
  const helpUrl = language.appLanguage === 'en' ? 'help' : 'yardim';
  const categoryUrl = language.appLanguage === 'en' ? 'category' : 'kategori';

  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const runAsync = async () => {
      const parameters = {
        method: 'GET',
        url: 'v1/help/categories',
        apiType: 'contentApi',
        language: language.appLanguage,
      };

      const request = await Request.getResponse(parameters);

      if (request.status === 200) {
        setResults(request.data.results);

        setLoading(false);
      } else {
        setLoading(false);
      }
    };

    runAsync();
  }, [language]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <SeoHeader
        robots
        title={i18n.t('helpSeoTitle')}
        description={i18n.t('helpSeoDescription')}
        keywords='help, help topics, breath hub, breathhub, breath hub app'
        canonical={`https://community.breathhub.app/${language.appLanguage}/${helpUrl}`}
      />
      <SiteLayout headerType='white' footerType='green'>
        <section className='green-header'>
          <div className='container'>
            <div className='row'>
              <div className='col-6'>
                <HelpSearch />
              </div>
            </div>
          </div>
        </section>
        <section className='white content-padding'>
          <div className='container'>
            <div className='help'>
              <div className='row'>
                {results &&
                  results.map((item) => (
                    <div className='col-2' key={item.id.toString()}>
                      <HelpBox
                        url={`/${language.appLanguage}/${helpUrl}/${categoryUrl}/${item.url}`}
                        text={item.name}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </section>
      </SiteLayout>
    </>
  );
};

export default HelpScreen;
