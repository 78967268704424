/* eslint-disable no-unused-vars */
import { memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';

import useMember from '../../hooks/useMember';
import useLanguage from '../../hooks/useLanguage';

import i18n from '../../i18n/i18n';

import Request from '../../tools/Request';

const PremiumCard = memo(({ path, name, pricing, transparent, location, period }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { member } = useMember();
  const { language } = useLanguage();

  const selectSubscription = async () => {
    setLoading(true);

    const parameters = {
      method: 'GET',
      url: `v1/stripe/checkout/community?period=${period}&path=${path}`,
      apiType: 'purchaseApi',
      headers: [{ AccessToken: member.token }],
      language: location === 'TR' ? 'tr' : 'en',
    };

    const request = await Request.getResponse(parameters);

    if (request.status === 200) {
      localStorage.setItem('purchase-product-url', window.location.href);
      window.location.href = request.data.results.sessionUrl;
    } else {
      setLoading(false);
    }
  };

  const redirectToSignin = () =>
    navigate(`/${language.appLanguage}/members/signup/${path}/${period}`);

  return (
    <div className={transparent ? 'transparent-item no-select' : 'green-item no-select'}>
      <div className='pro-title'>{parse(name)}</div>
      <div className='pro-des'>{parse(pricing)}</div>
      {loading ? (
        <button type='button' className='button button-white button-mini'>
          Loading...
        </button>
      ) : (
        <button
          type='submit'
          className='button button-white button-mini purchase-card-sub-btn'
          onClick={() => (member ? selectSubscription() : redirectToSignin())}
        >
          {i18n.t('selectCard')}
        </button>
      )}
    </div>
  );
});

export default PremiumCard;
