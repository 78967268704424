import { memo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const HelpBox = memo(({ url, text }) => (
  <Link to={url} className='help-box flex flex-center'>
    <p>{text}</p>
  </Link>
));

HelpBox.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default HelpBox;
