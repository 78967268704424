import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SeoHeader from '../../components/SeoHeader/SeoHeader';
import useLanguage from '../../hooks/useLanguage';

const NotFoundScreen = () => {
  const { language } = useLanguage();

  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/${language.appLanguage}`);
  }, []);

  return (
    <>
      <SeoHeader
        robots={false}
        title='Not found'
        description='Resource not found on server. (404).'
      />
      <div className='center'>
        <h3>Not found</h3>
        <p>Resource not found on server. (404)</p>
      </div>
    </>
  );
};

export default NotFoundScreen;
