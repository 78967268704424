import { useContext } from 'react';

import { MemberContext } from '../contexts/MemberContext';

const useMember = () => {
  const { member, removeMember, registerMember, isMemberSignedin } = useContext(MemberContext);

  return { member, removeMember, registerMember, isMemberSignedin };
};

export default useMember;
