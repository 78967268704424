const Loading = () => (
  <div className='loading'>
    <img
      src='https://breathhubimagecdn.azureedge.net/web/logos/360x360.png'
      alt='Loading'
      className='circle-anim'
      draggable='false'
    />
  </div>
);

export default Loading;
