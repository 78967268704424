import { memo } from 'react';
import { Link } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { IoChevronUpOutline } from 'react-icons/io5';
import { BsFacebook, BsYoutube, BsInstagram } from 'react-icons/bs';
import PropTypes from 'prop-types';

import useLanguage from '../../hooks/useLanguage';

import LanguageButton from './LanguageButton';

import i18n from '../../i18n/i18n';

const Footer = memo(({ type }) => {
  const { language } = useLanguage();
  const handleScrollClick = () => {
    scroller.scrollTo('pagetop', {
      smooth: true,
    });
  };

  return (
    <footer className={type}>
      <div className='footer-top'>
        <div className='container'>
          <div className='row'>
            <div className='col-1'>
              <a className='logo' href={`/${language.appLanguage}`}>
                &nbsp;
              </a>
            </div>
            <div className='col-5'>
              <div className='col-2 center'>
                <ul>
                  <li>
                    <Link to={`/${language.appLanguage}/legal/cookie-policy`}>
                      {i18n.t('cookiePolicy')}
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${language.appLanguage}/legal/acceptable-use-policy`}>
                      {i18n.t('acceptableUsePolicy')}
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${language.appLanguage}/legal/terms-and-conditions`}>
                      {i18n.t('termsAndConditions')}
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${language.appLanguage}/legal/privacy-policy`}>
                      {i18n.t('privacyPolicy')}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className='col-2 center' />
              <div className='col-3 right'>
                <div className='social'>
                  {/* <div className='circle green'>
                    <ImTwitter />
                  </div> */}
                  <a href='https://www.facebook.com/breathhubapp' target='_blank' rel='noreferrer'>
                    <div className='circle green'>
                      <BsFacebook />
                    </div>
                  </a>
                  <a
                    href={
                      language.appLanguage === 'tr'
                        ? 'https://instagram.com/breathhub_tr?igshid=MzRlODBiNWFlZA=='
                        : 'https://www.instagram.com/breathhubapp/'
                    }
                    target='_blank'
                    rel='noreferrer'
                  >
                    <div className='circle green'>
                      <BsInstagram />
                    </div>
                  </a>
                  <a
                    href='https://www.youtube.com/channel/UCW7ZWeg4r5f91rJz6l04h1w'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <div className='circle green'>
                      <BsYoutube />
                    </div>
                  </a>
                  {/* <div className='circle green'>
                    <ImLinkedin2 />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='footer-middle'>
        <div className='container'>
          <div className='row flex flex-center'>
            <div className='col-3 left'>
              <a href='https://apps.apple.com/app/id1531494892' target='_blank' rel='noreferrer'>
                <img
                  src='https://breathhubimagecdn.azureedge.net/web/stores/store-apple.png'
                  alt='App Store'
                  draggable='false'
                />
              </a>
              <a
                href='https://play.google.com/store/apps/details?id=app.breathhub.main1'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  src='https://breathhubimagecdn.azureedge.net/web/stores/store-google.png'
                  alt='Google Play'
                  draggable='false'
                />
              </a>
            </div>
            <div className='col-3 right'>
              <LanguageButton />
            </div>
          </div>
        </div>
      </div>

      <div className='footer-bottom'>
        <div className='container'>
          <div className='row flex flex-center'>
            <div className='col-3 left'>
              <p>&copy; 2023 Breath Hub</p>
            </div>
            <div className='col-3 right'>
              <div role='presentation' className='circle white' onClick={() => handleScrollClick()}>
                <IoChevronUpOutline size={20} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
});

Footer.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Footer;
