import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';

const SuccessMessage = memo(({ title, message, buttonText, click }) => {
  useEffect(() => {
    document.body.classList.add('no-scroll');

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  return (
    <div className='backdrop no-select'>
      <div className='success-message'>
        <h4>{title}</h4>
        <p>{message}</p>
        <button
          type='button'
          className='button button-green button-medium button-block'
          onClick={click}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
});

SuccessMessage.propTypes = {
  buttonText: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  click: PropTypes.func.isRequired,
};

export default SuccessMessage;
