import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';

import useLanguage from '../../hooks/useLanguage';

import SeoHeader from '../../components/SeoHeader/SeoHeader';
import SiteLayout from '../../components/Layouts/SiteLayout';
import Loading from '../../components/Loading/Loading';

import Request from '../../tools/Request';

const CreateHtmlContentArea = ({ html }) => {
  if (html) {
    return <div className='html-content'>{parse(html)}</div>;
  }

  return null;
};

const ContentScreen = () => {
  const { url } = useParams();
  const { language } = useLanguage();

  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState(null);
  const [founded, setFounded] = useState(true);

  useEffect(() => {
    const runAsync = async () => {
      setLoading(true);

      const parameters = {
        method: 'GET',
        url: `v1/content/${url}`,
        apiType: 'contentApi',
        language: language.appLanguage,
      };

      const request = await Request.getResponse(parameters);

      if (request.status === 200) {
        setResults(request.data.results);
      } else {
        setFounded(false);
      }

      setLoading(false);
    };

    runAsync();
  }, [url, language.appLanguage]);

  if (loading) {
    return <Loading />;
  }

  if (!founded) {
    return null;
  }

  return (
    <>
      <SeoHeader
        robots={results.contentSeo.robots}
        title={results.title}
        description={results.contentSeo.metaDescription}
        keywords={results.contentSeo.metaKeywords}
        canonical={`https://breathhub.app/${language.appLanguage}/legal/${url}`}
      />
      <SiteLayout headerType='white' footerType='green'>
        <section className='gray padding'>
          <div className='container'>
            <div className='row'>
              <div className='col-6'>
                <div className='site-content'>
                  <h1>{results.title}</h1>
                  <CreateHtmlContentArea html={results.contents} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </SiteLayout>
    </>
  );
};

export default ContentScreen;
