import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Analytics from '../tools/Analytics';

const useGoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    Analytics.init();
  }, []);

  useEffect(() => {
    const currentPath = location.pathname + location.search;

    Analytics.sendPageview(currentPath);
  }, [location]);
};

export default useGoogleAnalytics;
