import { memo, useState } from 'react';
import { IoChevronDownOutline } from 'react-icons/io5';

import useLanguage from '../../hooks/useLanguage';

import i18n from '../../i18n/i18n';

const LanguageButton = memo(() => {
  const { language, registerLanguage } = useLanguage();

  const [open, setOpen] = useState(false);

  const handleOpenPopup = () => {
    setOpen(!open);

    if (open) {
      document.body.classList.remove('no-scroll');
    } else {
      document.body.classList.add('no-scroll');
    }
  };

  const handleLanguageChange = (lang) => {
    const parameters = {
      appLanguage: lang,
    };

    registerLanguage(parameters);

    let url = document.location.href;
    if (url.includes(`/${language.appLanguage}/`)) {
      url = url.replace(`/${language.appLanguage}/`, `/${lang}/`);
    } else if (url.includes(`/${language.appLanguage}`)) {
      url = url.replace(`/${language.appLanguage}`, `/${lang}`);
    } else if (!url.includes(`${language.appLanguage}`)) {
      url = `${url}${lang}`;
    }

    document.location = url;
  };

  return (
    <>
      <button type='button' onClick={() => handleOpenPopup()} className='language-selector'>
        {language.appLanguage === 'en' ? 'English' : 'Türkçe'}
        <IoChevronDownOutline size={20} />
      </button>
      {open && (
        <div className='backdrop no-select'>
          <div className='success-message'>
            <h4>{i18n.t('languageTitle')}</h4>
            <p>{i18n.t('languageDescription')}</p>

            <div className='language-select'>
              <button
                type='button'
                onClick={() => handleLanguageChange('en')}
                className={language.appLanguage === 'en' ? 'current-lang' : 'selection'}
              >
                English
              </button>
              <button
                type='button'
                onClick={() => handleLanguageChange('tr')}
                className={language.appLanguage === 'tr' ? 'current-lang' : 'selection'}
              >
                Türkçe
              </button>
            </div>

            <button
              type='button'
              className='button button-green button-medium button-block'
              onClick={() => handleOpenPopup()}
            >
              {i18n.t('languageButton')}
            </button>
          </div>
        </div>
      )}
    </>
  );
});

export default LanguageButton;
