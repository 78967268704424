import { memo } from 'react';
import PropTypes from 'prop-types';

import i18n from '../../i18n/i18n';

const GetStarted = memo(({ white }) => (
  <section className={white ? 'gray padding-top' : 'white padding-top'}>
    <div className='container'>
      <div className={white ? 'white download' : 'download'}>
        <div className='row'>
          <div className='col-4 center'>
            <img
              src='https://breathhubimagecdn.azureedge.net/web/other/phones.png'
              alt=''
              className='image'
              draggable='false'
            />
          </div>
          <div className='col-2'>
            <h4>
              {i18n.t('getStartedLine1')}
              <br />
              <strong>{i18n.t('getStartedLine2')}</strong>
            </h4>
            <p>
              {i18n.t('mainHeroParagraph1')}
              <br />
              {i18n.t('mainHeroParagraph2')}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
));

GetStarted.defaultProps = {
  white: false,
};

GetStarted.propTypes = {
  white: PropTypes.bool,
};

export default GetStarted;
