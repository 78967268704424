import { memo } from 'react';

import Loader from '../Loading/Loader';
import CommunityCard from '../Cards/CommunityCard';

const CommunityList = memo(({ community, loading }) => {
  const shouldCenter = (index, totalItems) => {
    if ((totalItems + 1) % 3 === 0) {
      return false;
    }

    return (
      ((totalItems + 1) % 3 === 2 && index === totalItems - 1) ||
      index === totalItems ||
      ((totalItems + 1) % 3 === 1 && index === totalItems)
    );
  };

  return (
    <section className='white padding-top padding-bottom'>
      <div className='container'>
        <div className='partners'>
          {/*
            <div className='row'>
            <div className='col-6'>
              <div className='section-title section-title-padding flex flex-row flex-vertical-center flex-space-between'>
                <h4>
                 
                  <strong>{i18n.t('partnershipsTitleBold')}</strong>
                </h4>
              </div>
            </div>
          </div>
          */}

          {/*
<div className='container'>
            <div className='partners aboutbg'>
              <div className='row'>
                <div className='col-3'>
                  <div className='row imgrow'>
                    <div className='aboutimg'>
                      <img
                        className='responsive-image'
                        alt='NILogo'
                        src='https://breathhubstorage.blob.core.windows.net/images/web/banner/logo-nev-ins.png'
                      />
                    </div>
                  </div>
                  <div className='row rowpad'>
                    <div className='col-2 aboutleft'>
                      <p className='aboutp1'>{i18n.t('nevInsDes1')}</p>
                    </div>
                    <div className='col-1 aboutright' />
                    <div className='col-2 aboutright'>
                      <p className='aboutp1'>{i18n.t('nevInsDes2')}</p>
                    </div>
                  </div>
                </div>
                <div className='col-3'>
                  <div
                    className='video-container'
                    id='video-container-0'
                    style={{ maxWidth: '780px' }}
                  >
                    <div
                      className='responsive-video'
                      id='responsive-video-0'
                      style={{
                        position: 'relative',
                        display: 'block',
                        overflow: 'hidden',
                        height: '0px',
                        paddingBottom: '56.25%',
                      }}
                    >
                      <iframe
                        width='504'
                        height='284'
                        src='https://www.youtube-nocookie.com/embed/1M3cOcChSSU'
                        title='YouTube video player'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        allowFullScreen=''
                        style={{
                          position: 'absolute',
                          top: '0px',
                          bottom: '0px',
                          left: '0px',
                          width: '100%',
                          height: '100%',
                          border: '0px',
                          backgroundColor: 'rgb(0, 0, 0)',
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-6'>
                  <img
                    alt='About Logos'
                    src='https://breathhubstorage.blob.core.windows.net/images/web/banner/bant_green.png'
                    className='responsive-image'
                    draggable='false'
                  />
                </div>
              </div>
            </div>
          </div>

 */}

          <div className='row'>
            {loading ? (
              <Loader />
            ) : (
              community &&
              community.map((item, i) => (
                <div
                  className={`col-2 mt-4${
                    shouldCenter(i, community.length - 1)
                      ? `${
                          community.length % 3 === 1 ? ' center-element' : ' center-element-quarter'
                        }`
                      : ''
                  }`}
                  key={item.id.toString()}
                >
                  <CommunityCard
                    id={item?.id}
                    name={item?.name}
                    description={item?.shortDescription}
                    backgroundImage={item?.picture}
                    path={item?.path}
                  />
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </section>
  );
});

export default CommunityList;
