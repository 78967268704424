import { useContext } from 'react';

import { LanguageContext } from '../contexts/LanguageContext';

const useLanguage = () => {
  const { language, registerLanguage } = useContext(LanguageContext);

  return { language, registerLanguage };
};

export default useLanguage;
