import { useContext } from 'react';

import { LocationContext } from '../contexts/LocationContext';

const useLocation = () => {
  const { location, registerLocation } = useContext(LocationContext);

  return { location, registerLocation };
};
export const useLocationLocal = () => {
  const { location, registerLocation } = useContext(LocationContext);

  return { location, registerLocation };
};

export default useLocation;
