import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { GoogleOAuthProvider } from '@react-oauth/google';

import useAnalytics from './hooks/useAnalytics';
import Navigation from './navigation/Navigation';

import MemberProvider from './contexts/MemberContext';
import DrawerProvider from './contexts/DrawerContext';
import LanguageProvider from './contexts/LanguageContext';
import LocationProvider from './contexts/LocationContext';

const App = () => {
  useAnalytics();

  useEffect(() => {
    TagManager.initialize({ gtmId: 'G-G6F27JLHGW' });
  }, []);

  return (
    <GoogleOAuthProvider clientId='490698592640-d09sb0uhfpf76tqi1d7hkjql9be82je4.apps.googleusercontent.com'>
      <MemberProvider>
        <DrawerProvider>
          <LanguageProvider>
            <LocationProvider>
              <Navigation />
            </LocationProvider>
          </LanguageProvider>
        </DrawerProvider>
      </MemberProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
