/**
 * Convert time code to human readable format.
 *
 * @param {date} value - date to be converted.
 */
const humanReadableDate = (date) => {
  const myDate = new Date(date);

  return `${myDate.getFullYear()}/${myDate.getMonth() + 1}/${myDate.getDate()}`;
};

const Functions = {
  humanReadableDate,
};

export default Functions;
