import { memo } from 'react';
import PropTypes from 'prop-types';

const ImageHeader = memo(({ image, children }) => (
  <section className='image-header hero' style={{ backgroundImage: `url('${image}')` }}>
    <div className='container'>
      <div className='row'>
        <div className='col-6 center'>{children}</div>
      </div>
    </div>
  </section>
));

ImageHeader.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ImageHeader;
