export const apiURLs = {
  memberApi: 'https://breathhub-memberapi.azurewebsites.net',
  contentApi: 'https://breathhub-contentapi.azurewebsites.net',
  purchaseApi: 'https://breathhub-purchaseapi.azurewebsites.net',
};

export const auth = {
  username: '48761863',
  password:
    '48cd60525d34441d82f8d4c22ac5b750d0767aace76544d0b0692db78d0a550ba96c8e9dd81a465e925f416a66f78139',
};
