import { memo, useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineSearch } from 'react-icons/ai';

import useLanguage from '../../hooks/useLanguage';

import SuccessMessage from '../Forms/SuccessMessage';
import Loader from '../Loading/Loader';

import Request from '../../tools/Request';

import i18n from '../../i18n/i18n';

const HelpSearch = memo(() => {
  const { language } = useLanguage();

  const helpUrl = language.appLanguage === 'en' ? 'help' : 'yardim';
  const answerUrl = language.appLanguage === 'en' ? 'answer' : 'cevap';

  const [keyword, setKeyword] = useState('');
  const [success, setSuccess] = useState(null);
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { value } = e.target;

    setKeyword(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const bodyData = new FormData();

    bodyData.append('keyword', keyword);

    const parameters = {
      method: 'POST',
      url: 'v1/help/search',
      apiType: 'contentApi',
      data: bodyData,
      headers: [{ 'Content-Type': 'multipart/form-data' }],
      language: language.appLanguage,
    };

    const request = await Request.getResponse(parameters);

    if (request.status === 200) {
      setResults(request.data.results);
    } else {
      setResults(null);

      setSuccess({ title: i18n.t('error'), message: request.title, buttonText: i18n.t('ok') });
    }

    setLoading(false);
  };

  return (
    <>
      <div className='help-header center'>
        <h3>{i18n.t('helpH1')}</h3>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div
              className={
                results
                  ? 'help-search active flex flex-vertical-center'
                  : 'help-search flex flex-vertical-center'
              }
            >
              <AiOutlineSearch />
              <form onSubmit={handleSubmit} noValidate>
                <input
                  type='text'
                  name='search'
                  id='search'
                  value={keyword}
                  placeholder={i18n.t('helpSearchPlaceholder')}
                  onChange={handleChange}
                />
              </form>
            </div>
            {results && (
              <div className='autocompleter'>
                <ul>
                  {results.map((item) => (
                    <li key={item.id.toString()}>
                      <Link to={`/${helpUrl}/${answerUrl}/${item.url}`}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </>
        )}
      </div>
      {success && (
        <SuccessMessage
          title={success.title}
          message={success.message}
          buttonText={success.buttonText}
          click={() => setSuccess(null)}
        />
      )}
    </>
  );
});

export default HelpSearch;
