import { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Database from '../tools/Database';

import i18n from '../i18n/i18n';

const tableName = 'language';

const initialState = {
  language: {
    appLanguage: 'tr',
  },
  registerLanguage: () => {},
};

export const LanguageContext = createContext(initialState);

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(Database.getData(tableName) || initialState.language);

  /**
   * Add or update language parameters in database.
   *
   * @param {object} details - Language data
   */
  const registerLanguage = (details) => {
    setLanguage(details);

    i18n.locale = details.appLanguage;

    Database.setData(tableName, details);
  };

  // Get language parameter
  useEffect(() => {
    i18n.locale = language.appLanguage;
  }, []);

  /**
   * Create context values.
   */
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue = {
    language,
    registerLanguage: (details) => registerLanguage(details),
  };

  return <LanguageContext.Provider value={contextValue}>{children}</LanguageContext.Provider>;
};

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LanguageProvider;
