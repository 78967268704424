import { useState, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import parse from 'html-react-parser';

import useLanguage from '../../hooks/useLanguage';
import SeoHeader from '../../components/SeoHeader/SeoHeader';
import SiteLayout from '../../components/Layouts/SiteLayout';

import i18n from '../../i18n/i18n';
import Premium from '../../components/Sections/Premium';
import Loading from '../../components/Loading/Loading';
import Request from '../../tools/Request';
// import PeopleCard from '../../components/Cards/PeopleCard';
import useMember from '../../hooks/useMember';

const MU_PREVIEW_IMG_URL =
  'https://breathhubstorage.blob.core.windows.net/images/community/vid_kapak_2.jpg';

const NK_PREVIEW_IMG_URL =
  'https://breathhubstorage.blob.core.windows.net/images/community/vid_kapak_1.jpg';
const useQuery = () => new URLSearchParams(useLocation().search);

const CreateHtmlContentArea = ({ html }) => {
  if (html) {
    return (
      <div className='html-content' style={{ paddingLeft: 5 }}>
        {parse(html)}
      </div>
    );
  }

  return null;
};

const CommunityDetailScreen = () => {
  const query = useQuery();
  const vidMuRef = useRef(null);

  const { member } = useMember();

  const { language } = useLanguage();
  const { path } = useParams();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSub, setIsSub] = useState(true);
  const [isMuVideoStarted, setIsMuVideoStarted] = useState(false);

  const detailPathUrl = language?.appLanguage === 'en' ? 'community' : 'topluluk';

  useEffect(() => {
    let foundSub;
    if (
      data &&
      member &&
      member?.communityMemberSubscriptions &&
      Array.isArray(member?.communityMemberSubscriptions)
    ) {
      foundSub = member?.communityMemberSubscriptions?.find(
        (item) =>
          item?.masterId === data?.id && item?.status && new Date() - new Date(item?.endDate) < 0
      );
      setIsSub(foundSub);
    } else {
      setIsSub(false);
    }
  }, [member, data]);

  useEffect(() => {
    setLoading(false);
  }, [language]);

  useEffect(() => {
    const dl = query.get('dl');
    if (dl) {
      window.location.replace(dl);
    }
  }, []);

  useEffect(() => {
    const runAsync = async () => {
      if (path) {
        localStorage.setItem('meeting-path', path);
        setLoading(true);

        const parameters = {
          method: 'GET',
          url: `v1/community/${path}`,
          apiType: 'memberApi',
          language: language.appLanguage,
        };

        const request = await Request.getResponse(parameters);

        if (request?.status === 200) {
          setData(request?.data?.results);
        }

        setLoading(false);
      }
    };

    runAsync();
  }, [path]);

  if (loading) {
    return <Loading />;
  }

  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  const handlePlayVideo = async (vidRef) => {
    await sleep(250);
    vidRef?.current?.play();
    setIsMuVideoStarted(true);
  };

  const toggleMeetingButton = () => {
    let meetingLink;
    if (data?.id === 14) {
      meetingLink = document.querySelector('#SOIBTN_10xFitnessGzellikveyilikProgram');
    } else if (data?.id === 12) {
      meetingLink = document.querySelector('#SOIBTN_mufredatdonusturucukocluk');
    } else if (data?.id === 11) {
      meetingLink = document.querySelector('#SOIBTN_NefesKocluguSertifikaProgramEkibi');
    } else if (data?.id === 16) {
      meetingLink = document.querySelector('#SOIBTN_markaolzenginol');
    } else if (data?.id === 15) {
      meetingLink = document.querySelector('#SOIBTN_mucizekursu');
    } else {
      meetingLink = document.querySelector('#SOIBTN_15DakikalknGrmeSeans');
    }
    if (meetingLink) {
      meetingLink.click();
    }

    localStorage.setItem('meeting-path', path);
  };

  return (
    <>
      <SeoHeader
        robots
        title={i18n.t('communitySeoTitle')}
        description={i18n.t('communitySeoDescription')}
        keywords='people, breath hub people, breath hub, breathhub, breath hub app'
        canonical={`https://community.breathhub.app/${language.appLanguage}/${detailPathUrl}/${path}`}
      />
      <SiteLayout headerType='white' footerType='green'>
        <button type='button' className='sticky-button' onClick={toggleMeetingButton}>
          {i18n.t('meetingCreateDes')}
          <div className='button button-white2 button-medium rnd-clck'>
            <strong>{i18n.t('webbinarClick')}</strong>
          </div>
        </button>
        <section
          className='green-header'
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('${data?.bannerPicture}')`,
          }}
        >
          <div className='container people'>
            <div className='row'>
              <div className='col-6'>
                <h1>{data?.name}</h1>
                <h3>
                  <i>{`${data?.ownerName} ${data?.ownerLastName}`}</i>
                </h3>
              </div>
            </div>
          </div>
        </section>

        {data?.id !== 12 && (
          <section className='gray padding-top'>
            <div className='container'>
              <div className='row'>
                <div className='col-6'>
                  <div className='site-content'>
                    <div className='html-content' style={{ paddingLeft: 5 }}>
                      {data && <CreateHtmlContentArea html={data?.features} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {data?.id === 12 && (
          <>
            <h3 className='wht-title'>
              <strong>{parse(i18n.t('muContent1'))}</strong>
            </h3>
            <p className='wht-des'>{parse(i18n.t('muContent2'))}</p>
            <h3 className='wht-title'>
              <strong>{i18n.t('muFivePrenciple')}</strong>
            </h3>
            <div className='container'>
              <div className='row'>
                <div className='col-6'>
                  <img
                    src='https://breathhubstorage.blob.core.windows.net/images/community/mu-intro-icons.svg'
                    className='intro-img'
                    alt='inc'
                    draggable='false'
                  />
                  <p className='wht-des'>{parse(i18n.t('muContent3'))}</p>
                </div>
              </div>
            </div>

            <h3 className='wht-title'>
              <strong>{i18n.t('whatsInclude')}</strong>
            </h3>
            <div className='container'>
              <div className='row'>
                <div className='col-1-5'>
                  <div className='inc-card'>
                    <img
                      src='https://breathhubstorage.blob.core.windows.net/images/community/mu-inc-1.png'
                      className='inc-img'
                      alt='inc'
                      draggable='false'
                    />
                    <h4>{parse(i18n.t('muIncTitle1'))}</h4>
                    <p>{parse(i18n.t('muIncDes1'))}</p>
                  </div>
                </div>

                <div className='col-1-5'>
                  <div className='inc-card'>
                    <img
                      src='https://breathhubstorage.blob.core.windows.net/images/community/mu-inc-2.png'
                      className='inc-img'
                      alt='inc'
                      draggable='false'
                    />
                    <h4>{parse(i18n.t('muIncTitle2'))}</h4>
                    <p>{parse(i18n.t('muIncDes2'))}</p>
                  </div>
                </div>

                <div className='col-1-5'>
                  <div className='inc-card'>
                    <img
                      src='https://breathhubstorage.blob.core.windows.net/images/community/mu-inc-3.png'
                      className='inc-img'
                      alt='inc'
                      draggable='false'
                    />
                    <h4>{parse(i18n.t('muIncTitle3'))}</h4>
                    <p>{parse(i18n.t('muIncDes3'))}</p>
                  </div>
                </div>

                <div className='col-1-5'>
                  <div className='inc-card'>
                    <img
                      src='https://breathhubstorage.blob.core.windows.net/images/community/mu-inc-4.png'
                      className='inc-img'
                      alt='inc'
                      draggable='false'
                    />
                    <h4>{parse(i18n.t('muIncTitle4'))}</h4>
                    <p>{parse(i18n.t('muIncDes4'))}</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div className='col-6'>
          {data?.id === 12 && (
            <section onClick={() => handlePlayVideo(vidMuRef)} className='gray cursor-pointer'>
              <div className='row'>
                <div className='container'>
                  <div className='col-6 center'>
                    <div id='player-overlay'>
                      <video
                        ref={vidMuRef}
                        width='400'
                        className={`video ${!isMuVideoStarted ? 'hide' : ''}`}
                        controls
                      >
                        <source src='/video/mu.mp4' type='video/mp4' />
                        <source src='/video/mu.ogg' type='video/ogg' />
                        Your browser does not support HTML5 video.
                      </video>
                      <img
                        style={{
                          width: '400px',
                          height: 'auto',
                          maxWidth: '100%',
                          margin: '30px 0',
                        }}
                        src={MU_PREVIEW_IMG_URL}
                        className={`video-preview-img ${isMuVideoStarted ? 'hide' : ''}`}
                        alt='mu-preview-img'
                        draggable='false'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          {data?.id === 11 && (
            <section onClick={() => handlePlayVideo(vidMuRef)} className='gray cursor-pointer'>
              <div className='row'>
                <div className='container'>
                  <div className='col-6 center'>
                    <div id='player-overlay'>
                      <video
                        ref={vidMuRef}
                        width='400'
                        className={`video ${!isMuVideoStarted ? 'hide' : ''}`}
                        controls
                      >
                        <source src='/video/se.mp4' type='video/mp4' />
                        <source src='/video/se.ogg' type='video/ogg' />
                        Your browser does not support HTML5 video.
                      </video>
                      <img
                        style={{
                          width: '400px',
                          height: 'auto',
                          maxWidth: '100%',
                          margin: '30px 0',
                        }}
                        src={NK_PREVIEW_IMG_URL}
                        className={`video-preview-img ${isMuVideoStarted ? 'hide' : ''}`}
                        alt='mu-preview-img'
                        draggable='false'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          {data?.id === 14 && (
            <section className='gray'>
              <div className='row'>
                <div className='container'>
                  <div className='col-6 center'>
                    <div id='player-overlay'>
                      <video controls>
                        <source src='/video/10x.mp4' type='video/mp4' />
                        <source src='/video/10x.ogg' type='video/ogg' />
                        Your browser does not support HTML5 video.
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
        <div className='container'>
          <div className='row'>
            <div className={data.id === 12 || data.id === 11 ? 'col-3' : 'col-6'}>
              <div className='meeting-container'>
                {data?.id === 12 && (
                  <iframe
                    src='https://go.oncehub.com//nemufredat?&amp;dt=&amp;em=1&amp;Lightbox=1undefined&amp;Si=1'
                    id='SOI_nemufredat'
                    name='ScheduleOnceIframe'
                    title='Oncehub Iframe'
                    data-mysrc=''
                    height='400px'
                    width='90%'
                    style={{
                      border: 'solid 1px rgba(0, 0, 0,0.1)',
                      backgroundColor: 'rgba(0, 0, 0,0)',
                      marginTop: '20px',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      borderRadius: '10px',
                    }}
                    className='oncehub-iframe'
                  />
                )}
                {data?.id === 11 && (
                  <iframe
                    src='https://go.oncehub.com//nefeskoclugu?&amp;dt=&amp;em=1&amp;Lightbox=1undefined&amp;Si=1'
                    id='SOI_nefeskoclugu'
                    name='ScheduleOnceIframe'
                    title='Oncehub Iframe'
                    data-mysrc=''
                    height='400px'
                    width='90%'
                    style={{
                      border: 'solid 1px rgba(0, 0, 0,0.1)',
                      backgroundColor: 'rgba(0, 0, 0,0)',
                      marginTop: '20px',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      borderRadius: '10px',
                    }}
                    className='oncehub-iframe'
                  />
                )}
                {data?.id === 16 && (
                  <iframe
                    src='https://go.oncehub.com//markaolzenginol?&amp;dt=&amp;em=1&amp;Lightbox=1undefined&amp;Si=1'
                    id='SOI_markaolzenginol'
                    name='ScheduleOnceIframe'
                    title='Oncehub Iframe'
                    data-mysrc=''
                    height='400px'
                    width='90%'
                    style={{
                      border: 'solid 1px rgba(0, 0, 0,0.1)',
                      backgroundColor: 'rgba(0, 0, 0,0)',
                      marginTop: '20px',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      borderRadius: '10px',
                    }}
                    className='oncehub-iframe'
                  />
                )}
                {data?.id === 15 && (
                  <iframe
                    src='https://go.oncehub.com//mucizekursu?&amp;dt=&amp;em=1&amp;Lightbox=1undefined&amp;Si=1'
                    id='SOI_mucizekursu'
                    name='ScheduleOnceIframe'
                    title='Oncehub Iframe'
                    data-mysrc=''
                    height='400px'
                    width='90%'
                    style={{
                      border: 'solid 1px rgba(0, 0, 0,0.1)',
                      backgroundColor: 'rgba(0, 0, 0,0)',
                      marginTop: '20px',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      borderRadius: '10px',
                    }}
                    className='oncehub-iframe'
                  />
                )}
                {data?.id !== 14 &&
                  data.id !== 12 &&
                  data.id !== 11 &&
                  data.id !== 16 &&
                  data.id !== 15 && (
                    <iframe
                      src='https://go.oncehub.com//nemufredat?&amp;dt=&amp;em=1&amp;Lightbox=1undefined&amp;Si=1'
                      id='SOI_nemufredat'
                      name='ScheduleOnceIframe'
                      title='Oncehub Iframe'
                      data-mysrc=''
                      height='400px'
                      width='90%'
                      style={{
                        border: 'solid 1px rgba(0, 0, 0,0.1)',
                        backgroundColor: 'rgba(0, 0, 0,0)',
                        marginTop: '20px',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        borderRadius: '10px',
                      }}
                      className='oncehub-iframe'
                    />
                  )}

                <div className='meeting-text-container'>
                  <strong>{i18n.t('meetingCreateTitle')}</strong>
                </div>
              </div>
            </div>

            <div className='col-3'>
              {(data.id === 12 || data.id === 11) && (
                <div className='meeting-container'>
                  <a
                    href={
                      data.id === 12
                        ? 'https://event.webinarjam.com/go/live/1/lp9npayt2s4sk'
                        : 'https://event.webinarjam.com/go/live/2/7w41wamiqsmsw'
                    }
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img
                      src={
                        data.id === 12
                          ? 'https://breathhubstorage.blob.core.windows.net/images/web/heros/mufredat.png'
                          : 'https://breathhubstorage.blob.core.windows.net/images/web/heros/nefes_koclugu.png'
                      }
                      className='webbinar-img'
                      alt='Webbinar'
                      draggable='false'
                    />
                  </a>

                  <div className='webbinar-text-container'>
                    <strong>{i18n.t('gotoWebbinarTitle')}</strong>
                    <p>{i18n.t('gotoWebbinarDes')}</p>
                    <a
                      className='button button-white2 button-xl webbinar-link'
                      href={
                        data.id === 12
                          ? 'https://event.webinarjam.com/go/live/1/lp9npayt2s4sk'
                          : 'https://event.webinarjam.com/go/live/2/7w41wamiqsmsw'
                      }
                      target='_blank'
                      rel='noreferrer'
                    >
                      <strong>{i18n.t('webbinarClick')}</strong>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {data && <Premium isHaveSub={isSub} data={data} />}
      </SiteLayout>
    </>
  );
};

export default CommunityDetailScreen;
