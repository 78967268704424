export default {
  // common
  main: 'Main page',
  about: 'About',
  help: 'Help',
  account: 'Account',
  subscription: 'Breath Hub Subscription',
  subscriptionCommunity: 'Community Subscription',
  subscriptionCommunityOneYear: 'Subscribe for 1 Year',
  subscriptionCommunityOneMonth: 'Subscribe for 1 Month',
  subscriptionCommunityOneTime: 'One Time',
  subscriptionPerm: 'Subscribe for 3 Year',
  signout: 'Sign out',
  signin: 'Sign in',
  signup: 'Sign up',
  tryForFree: 'Try for free',
  signinToYourAccount: 'Sign in to your account',
  forgotPasswordLink: 'Forgot password?',
  contactUs: 'Contact us',
  cookiePolicy: 'Cookie policy',
  acceptableUsePolicy: 'Acceptable use policy',
  termsAndConditions: 'Terms and Conditions',
  privacyPolicy: 'Privacy policy',
  learnMore: 'Learn more',
  readMore: 'Read more',
  share: 'Share',
  active: 'Active',
  passive: 'Passive',
  ok: 'OK',
  error: 'Error',
  success: 'Success',
  goToHome: 'Go to home page',
  goToSignin: 'Go to sign in',
  goToProfile: 'Go to profile',
  goToSubscriptions: 'Go to subscription settings',
  select: 'Select',
  selectCard: 'Subscribe now',
  createMeeting: 'Contact with us',
  whatsInclude: "What's Included?",
  muFivePrenciple: '5 Principles of the Curriculum:',
  webbinarClick: 'Register',
  gotoWebbinarTitle: 'Webinar Access',
  gotoWebbinarDes: 'Sign up for the webinar to get detailed information about the training',

  meetingCreateTitle: 'Create an Appointment',
  meetingCreateDes: 'Make an Appointment for a Free Consultation',

  nevInsDes1:
    'The Nevsah Institute is a world-leading centre of self-mastery, meditation and breath work. We help people discover their authentic path and reach their truest potential.',
  nevInsDes2:
    'We support people’s personal development through one-to-one courses, group work and coaching sessions, corporate learning events and personal mentoring.',
  viewAll: 'View all',
  submit: 'Submit',
  seeAll: 'See all',
  or: 'Or',
  continueWithApple: 'Continue with Apple',
  continueWithGoogle: 'Continue with Google',
  goToHomePage: 'Main Page',

  // Order
  orderErrorTitle: 'Order cancelled',
  orderErrorDescription: 'Picked the wrong subscription? Shop around then come back to pay!',
  orderSuccessTitle: 'Order successfully completed',
  orderSuccessDescription:
    'You can download Breath Hub, sign-in with your email address and password, and start using it. Your account information for accessing community membership will be sent via email. Thank you!',

  contactSuccessTitle: 'Your appointment has been successfully created!',
  contactSuccessDescription:
    'To provide you with the best service, we kindly request your timely attendance at the meeting with our team.',

  // Sidebar
  accountHome: 'Account home',
  editProfile: 'Edit profile',
  changeEmail: 'Change email',
  changePassword: 'Change password',
  notifications: 'Notifications',
  subscriptions: 'Subscriptions',

  // Blog
  noPosts: 'No posts yet',
  blogDescription:
    'All the breathing techniques you need to know for feminine energy, a better sleep, a healthier life and more are on the Breath Hub Blog!',

  // Members
  through: 'Through',
  noSubscription: 'No subscription found',
  manageSubscription: 'Manage subscription',
  changeSettings: 'Save',
  resetMyPassword: 'Reset password',
  activateMyAccount: 'Activate my account',

  forgotPassword: 'Forgot password',
  passwordReset: 'Reset password',
  activateEmail: 'Activate email address',
  activateMembership: 'Activate membership',
  signedOut: `You're successfully signed out.`,
  readAllPolicies: 'Please read and agree to all policies.',
  invalidParameters:
    'Invalid parameters. Please copy & paste activation link to your browser address bar.',

  nameLabel: 'Name',
  namePlaceholder: 'Enter your name',
  lastnameLabel: 'Last name',
  lastnamePlaceholder: 'Enter your last name',
  newEmailLabel: 'New email address',
  newEmailPlaceholder: 'Enter your new email address',
  newEmailConfirmLabel: 'Confirm email address',
  newEmailConfirmPlaceholder: 'Re-enter your new email address',
  currentPasswordLabel: 'Current password',
  currentPasswordPlaceholder: 'Enter your current password',
  newPasswordLabel: 'New password',
  newPasswordPlaceholder: 'Enter your new password',
  newPasswordConfirmLabel: 'Confirm new password',
  newPasswordConfirmPlaceholder: 'Re-enter your new password',
  emailLabel: 'Email address',
  emailPlaceholder: 'Enter your email address',
  passwordLabel: 'Password',
  passwordPlaceholder: 'Enter your password',
  passwordConfirmLabel: 'Confirm password',
  passwordConfirmPlaceholder: 'Re-enter your password',
  activationCodeLabel: 'Activation code',
  activationCodePlaceholder: 'Enter your activation code',
  phoneLabel: 'Phone',
  phonePlaceholder: 'Enter your phone number',
  messageLabel: 'Message',
  messagePlaceholder: 'Enter your message',

  editProfileDescription: 'You can update your name and last name here.',
  changeEmailDescription:
    'Please enter your new email address. A verification message including your activation code will sent to your new email address.',
  changePasswordDescription:
    'Please enter your new password. It is good idea to use a strong password that you are not using elsewhere.',
  forgotPasswordDescription:
    'Please enter your registered email address. Instructions for resetting your password will be sent this email address.',
  signinDescription: 'Please enter your registered email address and password.',
  signupDescription: 'Please enter your email address and set a password.',
  // activationDescription:
  //   'Before you sign in, you must activate your account with the code sent to your e-mail address.',
  activationDescription:
    'Please verify your email account with the link sent to your email address.',
  resetPasswordDescription:
    'Please enter your new password. It is good idea to use a strong password that you are not using elsewhere.',

  marketingPermission: `I'd like to receive emails about news & updates from Breath Hub`,
  permissions1: 'I have read and agree to the terms and conditions and privacy policy',
  permissions2:
    'I consent to Breath Hub processing my personal data in accordance with its privacy policy',

  // Main hero
  mainHeroParagraph1: 'Download Breath Hub and start',
  mainHeroParagraph2: 'your breath journey today.',

  // Get started
  getStartedLine1: 'Breathe better,',
  getStartedLine2: 'live better!',

  // Contact
  contactParagraph: 'Breath Hub is a guided Breathing Meditation App for personal transformation.',

  // Partnerships
  partnershipsTitle: 'Breath Hub ',
  partnershipsTitleBold: 'Breath Hub X Nevsah Institute',
  meetingTitleBold: 'Schedule a Meeting With Our Team',

  // People
  peopleTitle: 'Community',
  peopleTitleBold: 'Leaders',
  peopleSeoH1: 'Breath Hub People',
  peopleSeoTitle: 'Breath Hub People | Breath Hub',
  peopleSeoDescription:
    'Meet the Breath Hub family, a personal transformation guide that supports your awareness!',

  // Community
  communitySeoTitle: 'Breath Hub Community | Breath Hub',
  communitySeoDescription:
    'Meet Breath Hub Communities, your personal transformation guide that supports your awareness!',

  communityPageLink: 'Community Page link',

  // Premium
  premiumFreeText: 'First 7 days free',
  premiumTitleTop: 'Make the most of',
  premiumTitleBottom: 'Breath Hub',
  premiumDescription1:
    'Your breath journey starts here. Start today and get the full experience with Breath Hub Premium.',
  premiumDescription2:
    'We have created exclusive packages to share your experience with your loved ones. Contact us to learn more.',

  // About
  aboutTitle: 'About',
  aboutH1: 'Who Are We?',
  aboutSeoTitle: 'Who Are We? | Breath Hub',
  aboutDescription: 'Our mission is to make the world breathe better, live better.',
  aboutSeoDescription:
    'Click to learn more about Breath Hub, a guided breathing meditation app that helps to heal stress and provides relaxation!',

  // Contact
  contactTitle: 'Contact us',
  contactDescription:
    'Breath Hub is a guided breathwork app for personal transformation, which helps to heal anxiety, stress, and supports relaxation, focus, sleep, fitness and mindfulness.',

  // Help
  helpSearchTitle: 'How can we help you?',
  helpH1: 'FAQ',
  helpSeoTitle: 'FAQ | Breath Hub',
  helpSeoDescription:
    'Click now for all your questions about Breath Hub, from account settings, subscription, content to usage tips!',
  helpSearchPlaceholder: 'Type something',
  helpMainButton: 'Go to help',

  // Language selection
  languageTitle: 'Select language',
  languageDescription: 'Please select a language below.',
  languageButton: 'Close',

  muContent1:
    'The Curriculum Program <br/> is now a collaboration of Breath Hub x Nevşah Institute.',
  muContent2:
    'Our powerful program combines five core principles for living life masterfully; so you can discover the best version of yourself.',
  muContent3:
    "Experiencing your uniqueness is the true and full expression of accepting your humanity. We don't want to change you in our curriculum, we want to support you to be more. Join us on this 5-year journey and structure your life from the inside out and return to your true self in the process.",
  muIncTitle1: 'UNLIMITED ACCESS TO CURRICULUM:',
  muIncTitle2: 'FREE SUBSCRIPTION DURING THE CURRICULUM / BREATH HUB APP:',
  muIncTitle3: 'MENTORING:',
  muIncTitle4: 'WORKSHOPS:',

  muIncDes1:
    'Enjoy all the content available on Breath Hub, including online workshops, training programs, breathwork sessions, community forums, videos, blogs, articles and expert support.',
  muIncDes2:
    'One-year subscription to Breath Hub, a guided Breathwork app with dozens of different breathwork courses, over 1000 Breathwork sessions created by over 250 breathwork instructors from around the world.',
  muIncDes3: 'Take advantage of one-on-one mentoring sessions every two weeks.',
  muIncDes4:
    'Access weekend workshops where you can ask for advice from our experts and get guidance on your progress.',
};
