import { memo } from 'react';
import { AiOutlinePlus, AiOutlineMinus, AiOutlineRight } from 'react-icons/ai';
import PropTypes from 'prop-types';

const HelpClose = memo(({ question, answer, opened, noIcon, onClick }) => (
  <>
    <button
      type='button'
      className='help-close flex flex-vertical-center flex-space-between'
      onClick={onClick}
    >
      {question}
      {!noIcon ? (
        <div className='help-close-icon'>{opened ? <AiOutlineMinus /> : <AiOutlinePlus />}</div>
      ) : (
        <div className='help-close-icon'>
          <AiOutlineRight />
        </div>
      )}
    </button>
    {opened && (
      <div className='help-answer'>
        <p>{answer}</p>
      </div>
    )}
  </>
));

HelpClose.defaultProps = {
  noIcon: false,
};

HelpClose.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  opened: PropTypes.bool.isRequired,
  noIcon: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default HelpClose;
